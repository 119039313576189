
import Records from '@/shared/services/records'
import { fieldFromTemplate, myLastStanceFor } from '@/shared/helpers/poll'
import { max, values, orderBy, compact } from 'lodash'
import BarIcon from '@/components/poll/common/icon/bar.vue'
import CountIcon from '@/components/poll/common/icon/count.vue'
import PieIcon from '@/components/poll/common/icon/pie.vue'
import GridIcon from '@/components/poll/common/icon/grid.vue'
import Vue from 'vue'

export default
  components: {BarIcon, CountIcon, PieIcon, GridIcon}
  props:
    poll: Object

  data: ->
    users: {}

  created: ->
    @watchRecords
      collections: ['users']
      query: =>
        @poll.results.forEach (option) =>
          option.voter_ids.forEach (id) =>
            Vue.set @users, id, Records.users.find(id)

