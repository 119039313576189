
import Records from '@/shared/services/records'

export default
  props:
    tags: Array
    showCounts: Boolean
    smaller: Boolean
    selected: String

