
import { fieldFromTemplate, myLastStanceFor } from '@/shared/helpers/poll'

import BarIcon from '@/components/poll/common/icon/bar.vue'
import CountIcon from '@/components/poll/common/icon/count.vue'
import PieIcon from '@/components/poll/common/icon/pie.vue'
import GridIcon from '@/components/poll/common/icon/grid.vue'

export default
  components: {BarIcon, CountIcon, PieIcon, GridIcon}
  props:
    poll: Object
    showMyStance: Boolean
    stanceSize: Number
      default: 20
    size:
      type: Number
      default: 40
  computed:
    myStance: -> myLastStanceFor(@poll)
    showPosition: -> 'proposal count'.split(' ').includes(@poll.pollType)
