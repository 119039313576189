import { render, staticRenderFns } from "./display.vue?vue&type=template&id=0d9e06b0&lang=pug&"
import script from "./display.vue?vue&type=script&lang=coffee&"
export * from "./display.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Space from '@/components/common/space.vue'
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {Space,VChip})
